import { useState, useEffect, useRef, useCallback } from "react";
import firebaseService from "../../../firebase_service/firebaseService";
import MsTable from "../../../components/Widgets/Table";
import CurrTime from "../../../components/Widgets/CurrTime";
import { Device, colType, DeviceReport } from "../../../Types";
import Editable from "../../../components/Widgets/Editable";
import {
  resizeCloudinary,
  onlineCheck,
  ModalActions,
} from "../../../utils/util";
import DeviceModal from "./DeviceModal";
import bb from "../../../assets/img/Box_all.gif";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import ScheduleBulkTaskModal from "../../ScheduleBulkTaskModal";
import { FaTrash } from "react-icons/fa";
import ButtonLoader from "../../../components/Widgets/ButtonLoader";

type OperatorFunction = (
  a: string | number | boolean,
  b: string | number | boolean,
) => boolean;

const operators: Record<string, OperatorFunction> = {
  "~": (a, b) => a !== b,
  ">": (a, b) => typeof a === "number" && typeof b === "number" && a > b,
  "<": (a, b) => typeof a === "number" && typeof b === "number" && a < b,
  "=": (a, b) => a === b,
};

function SearchUnRegDevice() {
  const [devNotFoundErr, setDevNotFoundErr] = useState(false);
  const [unRegSerial, setUnRegSerial] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [orderId, setOrderId] = useState<string>();
  const [unRegDevice, setUnRegDevice] = useState<Device>();
  const [showUnRegModal, setShowUnRegModal] = useState<boolean>(false);

  return (
    <>
      <Form noValidate style={{ display: "flex", flexDirection: "column" }}>
        {devNotFoundErr && <p style={{ color: "red" }}>Device Not Found</p>}
        <Form.Group
          controlId="formBasicPassword"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <InputGroup style={{ marginRight: "5px" }}>
            <Form.Control
              isInvalid={devNotFoundErr}
              placeholder="Serial or IDF..."
              required
              onChange={(e) => {
                setUnRegSerial(e.target.value);
              }}
              type="text"
            ></Form.Control>
          </InputGroup>

          <ButtonLoader
            title="search"
            disabled={!unRegSerial}
            style={{ color: "#0d6efd", fontSize: "12px", width: "50%" }}
            onClick={async () => {
              try {
                const res = await firebaseService.getDeviceBySerialOrIdf(
                  unRegSerial!,
                );
                setUnRegDevice(res);
                setShowUnRegModal(true);
              } catch (e) {
                setDevNotFoundErr(true);
                setTimeout(() => {
                  setDevNotFoundErr(false);
                }, 2000);
              }
            }}
          ></ButtonLoader>
        </Form.Group>
        <Form.Group
          controlId="formBasicPassword"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <InputGroup style={{ marginRight: "5px" }}>
            <Form.Control
              isInvalid={devNotFoundErr}
              placeholder="By email"
              required
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="text"
            ></Form.Control>
          </InputGroup>

          <ButtonLoader
            title="search"
            disabled={!email}
            style={{ color: "#0d6efd", fontSize: "12px", width: "50%" }}
            onClick={async () => {
              try {
                const res = await firebaseService.getDeviceByEmail(email!);
                setUnRegDevice(res);
                setShowUnRegModal(true);
              } catch (e) {
                setDevNotFoundErr(true);
                setTimeout(() => {
                  setDevNotFoundErr(false);
                }, 2000);
              }
            }}
          ></ButtonLoader>
        </Form.Group>
        <Form.Group
          controlId="formBasicPassword"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <InputGroup style={{ marginRight: "5px" }}>
            <Form.Control
              isInvalid={devNotFoundErr}
              placeholder="By order id"
              required
              onChange={(e) => {
                setOrderId(e.target.value);
              }}
              type="text"
            ></Form.Control>
          </InputGroup>

          <ButtonLoader
            title="search"
            disabled={!orderId}
            style={{ color: "#0d6efd", fontSize: "12px", width: "50%" }}
            onClick={async () => {
              try {
                const res = await firebaseService.getDeviceByOrderId(orderId!);
                setUnRegDevice(res);
                setShowUnRegModal(true);
              } catch (e) {
                setDevNotFoundErr(true);
                setTimeout(() => {
                  setDevNotFoundErr(false);
                }, 2000);
              }
            }}
          ></ButtonLoader>
        </Form.Group>
      </Form>
      {showUnRegModal && unRegDevice && (
        <Modal
          className="modal-big modal-primary"
          show={true}
          onHide={() => setShowUnRegModal(false)}
        >
          <DeviceModal setShowModal={setShowUnRegModal} row={unRegDevice} />
        </Modal>
      )}
    </>
  );
}

function Devices() {
  const [data, setData] = useState<Array<Device>>();
  const [tableId] = useState("devices");
  const [allSelected, setAllSelected] = useState(false);
  const [allRemoved, setAllRemoved] = useState(false);
  const [selected, setSelected] = useState<Array<Device>>([]);
  const [currentSelected, setCurrentSelected] = useState(false);
  const [AggSelection, setAggSelection] = useState<Array<Device>>();
  const [filteredSelected, setFilteredSelected] = useState<Array<Device>>([]);
  const [userFilter, setUserFilter] = useState<Array<string>>([]);
  const [modalAction, setModalAction] = useState<ModalActions>(
    ModalActions.HIDE,
  );
  const [mergedData, setMergedData] = useState<Array<Device>>();
  const [currSearch, setCurrSearch] = useState<string>();
  const [dataReport, setDataReport] = useState<Array<DeviceReport>>();
  useEffect(() => {
    const r = (machinesReport: Array<DeviceReport>) => {
      setDataReport(machinesReport);
    };
    firebaseService.listen("machinesReport", r);
    return () => {
      firebaseService.removeListener("machinesReport", r);
    };
  }, [dataReport]);

  const l = useRef((devices: Array<Device>) => {
    setData([...devices]);
  });

  useEffect(() => {
    if (data && dataReport) {
      const merged: Device[] = data.map((d) => {
        if (d.id === "wF5GpsYm3VRazGFwmAEplV0dfe73") {
          console.log(d);
        }
        const report = dataReport.find((r) => r.id === d.id);

        // The version we split the collection
        return report && d.version >= 454
          ? {
              ...d,
              ...report,
              location: {
                time: undefined,
                value: report.location,
              },
            }
          : d;
      });
      setMergedData(merged);
    }
  }, [data, dataReport]);

  useEffect(() => {
    const c = l.current;
    if (!currentSelected) firebaseService.listen<Device>("machines", l.current);
    else firebaseService.removeListener("machines", l.current);
    return () => firebaseService.removeListener("machines", c);
  }, [currentSelected]);

  const getReportData = useCallback(
    (device: Device) => {
      const machineReport = dataReport?.find((d) => d.id === device.id);
      return machineReport ? machineReport : device;
    },
    [dataReport],
  );

  const getReportDataLocation = useCallback(
    (device: Device) => {
      const machineReport = dataReport?.find((d) => d.id === device.id);
      if (machineReport && machineReport.location) {
        return {
          city: machineReport.location.city,
          country_code: machineReport.location.country_code,
        };
      } else {
        return {
          city: device.location?.value?.city,
          country_code: device.location?.value?.country_code,
        };
      }
    },
    [dataReport],
  );

  const tableColumns: Array<colType> = [
    {
      size: 40,
      noHeadline: true,
      sort: false,
      label: "Online",
      field: "online",
    },
    {
      sort: true,
      label: "Device ID",
      field: "idf",
    },
    {
      sort: true,
      label: "Location",
      field: "location",
    },
    {
      sort: true,
      label: "Heartbeat",
      field: "heartbeat",
    },
    {
      size: 90,
      sort: false,
      label: "Clock Time",
      field: "clockTime",
    },
    {
      sort: true,
      label: "Current Klydo id",
      field: "currentIdf",
    },
    {
      sort: false,
      label: "Current Klydo image",
      field: "image",
    },
    {
      sort: true,
      label: "registred",
      field: "registerProduct",
    },
    {
      size: 48,
      sort: false,
      label: "Version",
      field: "version",
    },
    {
      sort: false,
      label: "Tag",
      field: "tag",
    },
  ];

  function genActions() {
    return (
      <div style={{ display: "flex" }}>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setModalAction(ModalActions.DELETE);
          }}
        >
          {" "}
          Delete Klydos <FaTrash style={{ marginLeft: "5px" }} />
        </Button>

        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "82%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setModalAction(ModalActions.UPDATE);
          }}
        >
          {" "}
          Update App{" "}
          <i style={{ marginLeft: "5px" }} className="nc-icon nc-android"></i>
        </Button>

        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            padding: "6px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            setModalAction(ModalActions.PREVIEW);
          }}
        >
          Play Klydo{" "}
          <i
            style={{ marginLeft: "5px" }}
            className="nc-icon nc-button-play"
          ></i>
        </Button>

        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => setModalAction(ModalActions.SYNC)}
        >
          Sync Pool{" "}
          <i
            style={{ marginLeft: "5px" }}
            className="nc-icon nc-refresh-02"
          ></i>{" "}
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => setModalAction(ModalActions.SYNC_LOGS)}
        >
          Sync Logs
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => setModalAction(ModalActions.UPDATE_LOG_CONFIG)}
        >
          Update Log Config
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => {
            if (selected.length > 1)
              return alert("Only one device can be selected for logcat");
            else setModalAction(ModalActions.LOGCAT);
          }}
        >
          Logcat
        </Button>
        <Button
          style={{
            marginBottom: "5px",
            marginRight: "5px",
            fontSize: "80%",
            height: "fit-content",
            display: "flex",
            alignItems: "center",
            padding: "6px",
          }}
          onClick={() => {
            if (selected.length > 1)
              return alert("Only one device can be selected for logcat");
            else setModalAction(ModalActions.ONBOARDING);
          }}
        >
          OnBoard
        </Button>
      </div>
    );
  }

  const imgOrVideo = (klydo: { id: string; loopUrl: string }) => {
    const parseCloudinaryUrl = (url: string) => {
      const type = url.split(".").pop();
      const params = "c_scale,w_150";
      if (type === "mp4") {
        url = url.replace("mp4", "jpg");
        if (url!.includes("image/upload") || url.includes("video/upload")) {
          url = url.replace("/upload/", `/upload/${params}/`);
        } else {
          url = url.replace("/klydoclock/", `/klydoclock/${params}/`);
        }
      }
      return url;
    };
    if (klydo.id === "9d371dd0-f44a-4d4d-8aeb-a927a788c978")
      return (
        <img
          style={{ borderRadius: 50 + "%" }}
          loading="lazy"
          height={96}
          width={96}
          src={bb}
          alt="klydo"
        />
      );
    const url = parseCloudinaryUrl(klydo.loopUrl);
    return (
      <img
        style={{ borderRadius: 50 + "%" }}
        loading="lazy"
        height={96}
        width={96}
        src={resizeCloudinary(url, 96)}
        alt="klydo"
      />
    );
  };
  const tableRows = (r: Device, key: number) => {
    const reportDevice = getReportData(r);
    const location = getReportDataLocation(r);
    const city = location?.city ? location.city : "no city";
    const country = location?.country_code
      ? location.country_code
      : "no country";
    return {
      idf: <p title={r.id}>{r.idf}</p>,
      location: city + ", " + country,
      clockTime: reportDevice.clocktime ? (
        <CurrTime
          time={reportDevice.clocktime}
          offset={r.timeOffset?.value}
        ></CurrTime>
      ) : (
        "-no info-"
      ),
      currentIdf: r.klydo && (
        <NavLink
          to="/admin/klydos"
          onClick={() => {
            localStorage.setItem("klydossearch", r.klydo!.id);
          }}
          title={r.klydo.id}
        >
          {r.currentIdf}
        </NavLink>
      ),
      image: r.klydo?.loopUrl ? imgOrVideo(r.klydo) : "-no img-",
      heartbeat: reportDevice.heartbeat.toLocaleString("en-GB"),
      registerProduct: r.registerProduct.toLocaleString("en-GB"),
      version: (
        <NavLink
          to={r.version ? "/admin/versions" : ""}
          onClick={() => {
            if (r.version) {
              localStorage.setItem("versionssearch", r.version);
            }
          }}
        >
          {r.version || "-no info-"}
        </NavLink>
      ),
      online: (
        <i
          className={`fa ${r.premium && r.premium.length ? "fa-star" : "fa-circle"}  ${onlineCheck(reportDevice.heartbeat) ? "text-success" : "text-danger"}`}
        ></i>
      ),
      tag: (
        <Editable
          title="tag"
          default={r.tag?.value || ""}
          onSet={(value) => {
            return new Promise((v, x) => {
              firebaseService
                .updateConfig(r.id, "tag", value)
                .then(() => {
                  r.tag = { time: new Date(), value: value };
                })
                .catch(x);
            });
          }}
        ></Editable>
      ),
      key: key,
    };
  };

  function getKeyIgnoreCase(obj: Device, key: string) {
    const keys = Object.keys(obj);
    for (const k of keys) {
      if (k.toLowerCase() === key.toLowerCase()) return obj[k];
    }
  }

  return (
    <>
      {mergedData ? (
        <MsTable
          id={tableId}
          actions={() => genActions()}
          buttons={<SearchUnRegDevice />}
          onAction={(selectedDevices: Array<Device>) => {
            setSelected(selectedDevices);
            if (currentSelected) {
              if (allSelected) {
                userFilter.push("+" + currSearch!);
                setAllSelected(false);
                setCurrentSelected(false);
              } else if (allRemoved) {
                userFilter.push("-" + currSearch!);
                setAllRemoved(false);
                setCurrentSelected(false);
              } else {
                setUserFilter([]);
                return;
              }
              setUserFilter((prev) => prev.slice());
              setFilteredSelected(AggSelection!);
            }
          }}
          onSearchChanged={(currentSearch: string) => {
            if (currentSelected) {
              if (allSelected) {
                userFilter.push("+" + currSearch!);
                setAllSelected(false);
                setCurrentSelected(false);
              } else if (allRemoved) {
                userFilter.push("-" + currSearch!);
                setAllRemoved(false);
                setCurrentSelected(false);
              } else {
                setUserFilter([]);
                return;
              }
              setUserFilter((prev) => prev.slice());
              setFilteredSelected(AggSelection!);
            }
            setCurrSearch(currentSearch);
          }}
          onSelectedChanged={(
            currSelected: Array<Device>,
            currTableData: Array<Device>,
          ) => {
            if (currSelected.length === 0) {
              setCurrentSelected(false);
              setUserFilter([]);
              return;
            }
            let isAllSelected: boolean = true;
            let isAllRemoved: boolean = true;

            for (let i = 0; i < currTableData.length; i++) {
              if (currSelected.includes(currTableData[i])) {
                isAllRemoved = false;
              } else {
                isAllSelected = false;
              }
              if (!isAllRemoved && !isAllSelected) break;
            }
            setAllSelected(isAllSelected);

            setAllRemoved(isAllRemoved);

            setCurrentSelected(
              isAllRemoved
                ? currTableData.some((device) =>
                    filteredSelected.includes(device),
                  )
                : true,
            );
            setAggSelection(currSelected);
          }}
          actionDisabled={(selection: Array<Device>) => {
            if (
              firebaseService.general?.maxMultipleCommand &&
              selection.length <= firebaseService.general?.maxMultipleCommand
            )
              return;
            return `The multiple commands feature is limited to ${firebaseService.general?.maxMultipleCommand} devices (to increase limit contact the dev team)`;
          }}
          rowBuild={tableRows}
          initSort="registerProduct"
          search={(r: Device, val: string) => {
            if (parseInt(val) && r.version === parseInt(val)) {
              return true;
            }
            if (val.endsWith("//config")) {
              const vall = val.split("//")[0].split(/[=><~]/);
              if (vall[0] === "range") {
                if (!vall[1]) return false;
                const values = vall[1].split(",").map((i) => parseFloat(i));
                if (values.length !== 4 || values.includes(NaN)) return false;
                if (!r.location || !r.location.value.longitude) return false;
                const lng = r.location.value.longitude;
                const lat = r.location.value.latitude;
                return (
                  lat >= values[0] &&
                  lat <= values[1] &&
                  lng >= values[2] &&
                  lng <= values[3]
                );
              }
              const prop = getKeyIgnoreCase(r, vall[0].split(".")[0]);
              const operator = operators[val.charAt(vall[0].length)];
              if (!operator || !vall[1]) return false;
              if (vall[1] === "null") {
                const char = val.charAt(vall[0].length);
                return (!prop && char === "=") || (prop && char === "~");
              }
              if (!prop) return false;
              let propVal = Object.keys(prop).includes("value")
                ? prop.value
                : prop;
              if (typeof propVal == "string") propVal = propVal.toLowerCase();
              else if (typeof propVal == "boolean") propVal = propVal + "";
              else if (typeof propVal == "object" && vall[0].includes(".")) {
                const keys = vall[0].split(".").slice(1);
                for (const k of keys) {
                  propVal = propVal[k];
                  if (!propVal) return vall[1] === "null";
                }
                if (typeof propVal == "string") propVal = propVal.toLowerCase();
              }
              return operator(propVal, vall[1]);
            }
            if (
              r.clientDetails &&
              r.clientDetails.name?.toLowerCase().includes(val)
            )
              return true;
            if (
              r.clientDetails &&
              r.clientDetails.email?.toLowerCase().includes(val)
            )
              return true;
            return (
              r.idf?.toLowerCase()?.includes(val) ||
              r.id.toLowerCase().includes(val) ||
              r.currentIdf?.toLowerCase().includes(val) ||
              r.tag?.value.toLowerCase().includes(val)
            );
          }}
          data={mergedData!}
          cols={tableColumns}
          title="Devices"
          modal={DeviceModal}
        />
      ) : (
        <Spinner></Spinner>
      )}
      {modalAction !== ModalActions.HIDE && (
        <ScheduleBulkTaskModal
          open={true}
          userFilter={userFilter!}
          handleClose={() => {
            setModalAction(ModalActions.HIDE);
          }}
          action={modalAction!}
          selectedDevices={selected}
        />
      )}
    </>
  );
}

export default Devices;
