import { useState } from "react";
import MsTable from "../../../../components/Widgets/Table";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { colType } from "../../../../Types";
import FloatAddButton from "../../../../components/Widgets/FloatAddButton";
import { Gift } from "../../../../api/giftCategories";
import useGiftOrdersQuery from "../hooks/useGiftOrdersQuery";
import GiftOrderModal from "./GiftOrderModal";

export type GiftRow = {
  orderId: string;
  date: string;
  numOfClocks: number;
  name: string;
  image: JSX.Element;
};
function GiftOrders() {
  const { data: giftOrders } = useGiftOrdersQuery();
  const [addNewOrderGift, setAddNewOrderGift] = useState<boolean>(false);

  const tableColumns: Array<colType> = [
    { label: "order id", field: "orderId", sort: false },
    { label: "name", field: "name", sort: false },
    { label: "Author Image", field: "image", sort: false },
    { label: "Date", field: "date", sort: false },
    { label: "Number of clocks", field: "numOfClocks", sort: false },
  ];

  const tableRows = (gift: Gift): GiftRow => {
    return {
      name: gift.senderName,
      numOfClocks: gift.clocksIds?.length || 0,
      orderId: gift.orderId,
      date: gift.createdAt.toString(),
      image: (
        <img width={100} src={gift.senderImageUrl} alt={gift.senderName} />
      ),
    };
  };

  const AddGiftOrderModal = () => {
    const [orderId, setOrderId] = useState("");
    const [showWarning, setShowWarning] = useState(false);
    const addNewCategory = async () => {
      if (!orderId) {
        setShowWarning(true);
        return;
      }
      setShowWarning(false);
      window.open(
        `https://editor.klydoclock.com/gift?orderId=${orderId}`,
        "_blank",
        "noreferrer",
      );
    };
    return (
      <>
        <Modal.Header>
          <Modal.Title>Add Gift to order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showWarning && (
            <Alert variant="danger">Enter Order Id to add gift to order</Alert>
          )}
          <Form>
            <Form.Group controlId="formGiftCategoryName">
              <Form.Label>Order Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Order Id"
                value={orderId}
                onChange={(e) => setOrderId(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setAddNewOrderGift(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              addNewCategory();
              setAddNewOrderGift(false);
            }}
          >
            Add Gift to Order
          </Button>
        </Modal.Footer>
      </>
    );
  };

  return (
    <>
      {giftOrders && (
        <>
          <MsTable
            id="Gift Orders"
            data={giftOrders}
            cols={tableColumns}
            rowBuild={tableRows}
            title="Gift Orders"
            search={(row: Gift, val: string) => {
              return Object.values(row).some(
                (field) =>
                  typeof field === "string" &&
                  field.toLowerCase().includes(val.toLowerCase()),
              );
            }}
            initSort=""
            cancelSort={true}
            modal={GiftOrderModal}
          />
          <FloatAddButton onClick={() => setAddNewOrderGift(true)} />
        </>
      )}
      {addNewOrderGift && (
        <Modal show={true} size="xl" onHide={() => setAddNewOrderGift(false)}>
          <AddGiftOrderModal />
        </Modal>
      )}
    </>
  );
}

export default GiftOrders;
