import { useState, useEffect, FormEventHandler } from "react";
// react-bootstrap components
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import firebaseService from "../firebase_service/firebaseService";
import MsTable from "../components/Widgets/Table";
import ButtonLoader from "../components/Widgets/ButtonLoader";
import { Version, colType } from "../Types";
import { NavLink } from "react-router-dom";
import adminApiClient from "../api/admin";

function Versions() {
  const [data, setData] = useState<Array<Version>>();
  const tableId = "versions";
  useEffect(() => {
    const l = (versions: Array<Version>) => {
      setData([...versions]);
    };
    firebaseService.listen<Version>("version", l);
    return () => {
      firebaseService.removeListener("version", l);
    };
  }, []);

  const [validated, setValidated] = useState(false);
  const [validated2, setValidated2] = useState(false);
  const [publicVersion, setPublicVersion] = useState<number>();
  const [beta, setBeta] = useState<number>();
  useEffect(() => {
    setPublicVersion(firebaseService.general?.public);
    setBeta(firebaseService.general?.beta);
  }, [firebaseService.general]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [errors2, setErrors2] = useState("");

  const tableColumns: Array<colType> = [
    {
      sort: true,
      size: 75,
      label: "Version",
      field: "version",
    },
    {
      sort: true,
      label: "Date",
      field: "date",
    },
    {
      size: 700,
      sort: false,
      label: "Description",
      field: "description",
    },
    {
      sort: true,
      label: "Was Beta",
      field: "wasBeta",
    },
    {
      sort: true,
      label: "Was Public",
      field: "wasPublic",
    },
    {
      sort: true,
      label: "Devices",
      field: "devices",
    },
  ];

  const tableRows = (r: Version, key: string) => {
    return {
      version: <p className={r.devices === 0 ? "disabled" : ""}>{r.version}</p>,
      date: (
        <p className={r.devices === 0 ? "disabled" : ""}>
          {r.date.toLocaleString("en-GB")}
        </p>
      ),
      description: (
        <p className={r.devices === 0 ? "disabled" : ""}>{r.description}</p>
      ),
      wasPublic: <p>{r.wasPublic?.toString()}</p>,
      wasBeta: <p>{r.wasBeta?.toString()}</p>,
      devices: (
        <NavLink
          style={r.devices === 0 ? { cursor: "not-allowed" } : {}}
          to={r.devices > 0 ? "/admin/devices" : ""}
          onClick={() => {
            if (r.devices > 0) {
              localStorage.setItem("devicessearch", r.version.toString());
            }
          }}
        >
          <p className={r.devices === 0 ? "disabled" : ""}>{r.devices}</p>
        </NavLink>
      ),
      key: key,
    };
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
    }
    if (form.checkValidity()) {
      setShow(true);
      setValidated(true);
    }
  };

  const buttons = () => {
    return (
      <div>
        <div style={{ display: "inline-block" }}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {firebaseService.general?.public === publicVersion ? (
              <Form.Label style={{ paddingRight: 15, color: "black" }}>
                Public version:
              </Form.Label>
            ) : (
              <></>
            )}
            <Form.Group controlId="formBasicPassword">
              <Form.Control
                isInvalid={errors !== ""}
                value={publicVersion}
                onChange={(e) => {
                  const validated =
                    data?.find(
                      (v) => v.version.toString() === e.target.value,
                    ) !== undefined;
                  setValidated(validated);
                  setErrors(validated ? "" : "There is no such version");
                  setPublicVersion(parseFloat(e.target.value));
                }}
                type="number"
                placeholder="Enter version"
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              {firebaseService.general?.public !== publicVersion &&
              validated ? (
                <Button type="submit">Publish</Button>
              ) : (
                <></>
              )}
            </Form.Group>
          </Form>
        </div>
        <div style={{ display: "inline-block" }}>
          <Form noValidate validated={validated2}>
            {firebaseService.general?.beta === beta ? (
              <Form.Label style={{ paddingRight: 15, color: "black" }}>
                Beta version:
              </Form.Label>
            ) : (
              <></>
            )}
            <Form.Group controlId="formBasicPassword">
              <Form.Control
                value={beta}
                isInvalid={errors2 !== ""}
                onChange={(e) => {
                  const validated =
                    data?.find(
                      (v) => v.version.toString() === e.target.value,
                    ) !== undefined;
                  setValidated2(validated);
                  setErrors2(validated ? "" : "There is no such version");
                  setBeta(parseFloat(e.target.value));
                }}
                type="number"
                placeholder="Enter version"
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors2}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              {firebaseService.general?.beta !== beta && validated2 && (
                <ButtonLoader
                  title="send to beta"
                  onClick={() =>
                    adminApiClient
                      .publishVersion({ version: beta!, update: "beta" })
                      .then(() => setBeta(firebaseService.general?.beta))
                  }
                />
              )}
            </Form.Group>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <>
      {data ? (
        <MsTable
          id={tableId}
          buttons={buttons()}
          rowBuild={tableRows}
          search={(row: Version, val: string) => {
            if (parseInt(val)) {
              return row.version === parseInt(val);
            }
            return row.description.toLowerCase().includes(val);
          }}
          data={data!}
          initSort={"version"}
          cols={tableColumns}
          title="Versions"
        />
      ) : (
        <Spinner></Spinner>
      )}
      <Modal
        animation={false}
        show={show}
        onHide={() => setShow(false)}
        backdrop={!loading}
        keyboard={true}
      >
        <Modal.Header>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>This version will be available for all users</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShow(false)}>Cancel</Button>
          <ButtonLoader
            title="OK"
            onClick={() => {
              setLoading(true);
              adminApiClient
                .publishVersion({ version: publicVersion!, update: "public" })
                .then(() => {
                  setLoading(false);
                  setPublicVersion(firebaseService.general?.public);
                  setShow(false);
                });
            }}
          ></ButtonLoader>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Versions;
