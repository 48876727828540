import { useQuery } from "@tanstack/react-query";
import giftCategoriesClient from "../../../../api/giftCategories";

const useCategoriesQuery = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: () => giftCategoriesClient.getAllCategories(),
  });
};
export default useCategoriesQuery;
