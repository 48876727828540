import { Klydo, Pack, ReviewScheme } from "Types";
import firebaseService from "firebase_service/firebaseService";
import { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
import ButtonLoader from "components/Widgets/ButtonLoader";
import Packview from "./Packview/Packview";
import useUsers from "components/hooks/useUsers";
import MultiSelect from "components/forms/MultiSelect";
import KlydoListItem from "./KlydoListItem";
import { FaCheck, FaX } from "react-icons/fa6";
import packService from "firebase_service/packService";

const getRandomTag = (): string => {
  let rndTag = "$p_";
  const chars = "abcdefghijklmnopqrstuvwxyz";
  for (let i = 0; i < 5; i++) {
    const rndIndex = Math.floor(Math.random() * chars.length);
    rndTag += chars.charAt(rndIndex);
  }
  return rndTag;
};

const PackReviewModal = (props: {
  row?: Pack;
  setShowModal: (b: boolean) => void;
  data: Pack[];
}) => {
  const scale = 1;
  const w = scale * 390;
  const h = scale * 643.5;
  const [imageUrl, setImageUrl] = useState<any>(
    props.row && props.row.imageUrl,
  );
  const [logoUrl, setLogoUrl] = useState<any>(props.row && props.row.logoUrl);
  const [pendulumImageUrl, setPendulumImageUrl] = useState<any>(
    props.row && props.row.pendulumImageUrl,
  );
  const [error, setError] = useState<{
    server?: string;
    klydo?: string;
    pic?: string;
  }>({});
  const [name, setName] = useState<string>(props.row?.name || "");
  const [review, setReview] = useState<ReviewScheme>(props.row?.review! || "");
  const [artists, setArtists] = useState<string[]>(props.row?.artists || []);
  const [tag] = useState<string>(props.row ? props.row.tag : getRandomTag());
  const [description, setDescription] = useState<string>(
    props.row?.description || "",
  );
  const [countChar, setCountChar] = useState<number>();
  const [draftKlydos, setDraftKlydos] = useState<Klydo[] | undefined>(
    props.row ? undefined : [],
  );
  const [removedKlydos, setRemovedKlydos] = useState<Klydo[]>([]);
  const [klydoValid, setKlydoValid] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<string>();
  const [inputText, setInputText] = useState<string>("");
  const users = useUsers();
  const [selectedKlydo, setSelectedKlydo] = useState<Klydo>();
  const [suggestions, setSuggestions] = useState<Array<Klydo>>();
  const [publishedPack, setPublishedPack] = useState<Pack>();
  const descriptionMaxLength = 420;

  const [innerModal, setInnerModal] = useState<
    "hide" | "reject" | "accept" | "delete"
  >("hide");
  const [alert, setAlert] = useState<string>();
  const [rejection, setRejection] = useState("");

  useEffect(() => {
    if (props.row && !draftKlydos) {
      if (props.row.klydos && props.row.klydos.length > 0)
        firebaseService
          .getList<Klydo>("klydos", "id", props.row.klydos)
          .then(setDraftKlydos);
      else setDraftKlydos([]);
      firebaseService
        .findItem<Pack>("packs", "id", props.row.id)
        .then(setPublishedPack);
    }
  }, [props.row]);

  useEffect(() => {
    let klydo: Klydo | undefined = undefined;
    let validated = false;
    let idf = inputText.split("-").length === 3;
    firebaseService
      .findItem<Klydo>("klydos", idf ? "idf" : "id", inputText)
      .then((v) => {
        klydo = v;
        validated = klydo !== undefined;
        setKlydoValid(validated);

        setError((error) =>
          validated || inputText.length === 0
            ? { ...error, klydo: "" }
            : { ...error, klydo: "There is no such klydo" },
        );
        if (validated) setSelectedKlydo(klydo!);
      });
  }, [inputText]);

  const publish = async () => {
    const localRemoveKlydos = publishedPack?.klydos!.filter(
      (kid) => !props.row!.klydos!.find((klydo) => klydo === kid),
    );
    console.log(localRemoveKlydos);
    const localAddKlydos = draftKlydos!.filter(
      (klydo) => !klydo.tags?.includes(tag!),
    );
    const prmss: Promise<void>[] = [];
    if (localRemoveKlydos && localRemoveKlydos.length) {
      const rmvKlds = await firebaseService.getList<Klydo>(
        "klydos",
        "id",
        localRemoveKlydos,
      );
      prmss.push(
        ...rmvKlds!.map((kld) =>
          firebaseService.saveKlydoTags(
            kld,
            kld.tags!.filter((t) => t !== tag),
          ),
        ),
      );
    }
    if (localAddKlydos!.length)
      prmss.push(
        ...localAddKlydos!.map((kld) =>
          firebaseService.saveKlydoTags(kld, [...kld.tags, tag!]),
        ),
      );
    const obj = {};
    prmss.push(packService.updatePack(props.row!.id, obj));
    return Promise.all(prmss)
      .then(() => {
        props.setShowModal(false);
      })
      .catch((e) => {
        console.log("e:", e);
        setError({ ...error, server: "" + e });
      });
  };

  const handleRemoveKlydo = (klydo: Klydo) => {
    setDeleting(klydo.id);
    removedKlydos.push(klydo);
    setRemovedKlydos((prev) => prev.slice());
    if (draftKlydos) {
      draftKlydos.splice(draftKlydos.indexOf(klydo), 1);
    }
    setDraftKlydos((prev) => prev?.slice());
    setDeleting(undefined);
  };

  return (
    <>
      <Modal.Header style={{ marginLeft: 24, marginTop: 0, marginBottom: 0 }}>
        <h4>Review Info</h4>
        <i
          className="nc-icon nc-simple-remove"
          style={{ marginTop: 15 }}
          onClick={() => props.setShowModal(false)}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            {review.type === "delete" && (
              <h3>
                The author has requested this pack to be removed from public.
                Accept to remove
              </h3>
            )}
            {review.type === "edit" && (
              <h3>
                The author has requested this pack to be public. Accept to
                publish
              </h3>
            )}
            <FaCheck
              size={25}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "10 10 0 10",
                color: review.type === "edit" ? "lightgray" : "black",
              }}
              onClick={() =>
                review.type === "edit"
                  ? setInnerModal("accept")
                  : review.type === "delete"
                    ? setInnerModal("delete")
                    : null
              }
            />
            <FaX
              size={25}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "10 10 0 10",
              }}
              onClick={() => setInnerModal("reject")}
            />
          </div>
          <Row
            style={{
              paddingBottom: 8,
            }}
          >
            <Card style={{ width: "50rem" }}>
              <Card.Body style={{}}>
                <Col md={25}>
                  <h5>Pack Details</h5>
                  <p>
                    <strong>Name:</strong> {props.row!.name}
                  </p>
                  <p>
                    <strong>Tag:</strong> {props.row!.tag || tag}
                  </p>
                  <p>
                    <strong>Description:</strong> {props.row!.description}
                  </p>
                  <p>
                    <strong>Artists:</strong> {props.row!.artists?.join(", ")}
                  </p>
                  <p>
                    <strong>Creator:</strong> {props.row!.creatorId}
                  </p>
                  <p>
                    <strong>Created At:</strong> {props.row!.createdAt + ""}
                  </p>

                  <strong>Klydos:</strong>
                  <ListGroup style={{ maxHeight: "200px", overflowY: "auto" }}>
                    {draftKlydos && draftKlydos.length > 0 ? (
                      <div>
                        <ListGroup
                          style={{
                            width: "50%",
                            borderRadius: 0,
                            border: "none",
                          }}
                          className="border-0"
                        >
                          <ListGroup.Item style={{ border: 0, height: "90%" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "12px",
                                color: "gray",
                                border: 0,
                              }}
                            >
                              <div style={{ width: 110 }} className="mb-1">
                                IDF
                              </div>
                              <div style={{ width: 110 }} className="mb-1">
                                Author
                              </div>
                              <div
                                style={{
                                  width: 35,
                                  left: "7px",
                                  position: "relative",
                                }}
                                className="mb-1"
                              >
                                GIF
                              </div>
                              <div
                                style={{ width: 25, position: "relative" }}
                                className="mb-1"
                              >
                                Pool
                              </div>
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                        <ListGroup
                          style={{
                            width: "50%",
                            borderRadius: 0,
                            border: "none",
                            maxHeight: "190px",
                            overflowY: "auto",
                          }}
                          className="border-0"
                        >
                          {draftKlydos!.map((klydo) => (
                            <KlydoListItem
                              key={klydo.id}
                              klydo={klydo}
                              onRemove={handleRemoveKlydo}
                              canRemove={false}
                            />
                          ))}
                        </ListGroup>
                      </div>
                    ) : draftKlydos ? (
                      <p>No klydos in draft</p>
                    ) : (
                      <Spinner />
                    )}
                  </ListGroup>
                </Col>
              </Card.Body>
            </Card>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: w + "px",
                  height: h + "px",
                  position: "relative",
                }}
              >
                <Packview
                  clockBackground={imageUrl}
                  pendulumBackground={pendulumImageUrl}
                  packName={name}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <div className="modal-footer justify-content-end">
        <Button
          className="btn-simple right"
          type="button"
          variant="link"
          onClick={() => props.setShowModal(false)}
        >
          Close
        </Button>
      </div>
      <Modal
        className="modal-medium"
        show={innerModal !== "hide"}
        onHide={() => setInnerModal("hide")}
      >
        <Modal.Body>
          {innerModal === "hide" ? (
            <></>
          ) : innerModal === "reject" ? (
            <>
              <Form>
                <Row>
                  <Form.Label as="h5" style={{ paddingTop: 12 }}>
                    <b>add rejuction reason:</b>
                  </Form.Label>
                </Row>
                <Row className="h-100">
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={rejection}
                    onChange={(e) => {
                      setRejection(e.target.value);
                    }}
                  ></Form.Control>
                </Row>
              </Form>
              <Row className="justify-content-between">
                <Col sm="5">
                  <Button onClick={() => setInnerModal("hide")}>Cancel</Button>
                </Col>
                <Col sm="5">
                  <Button
                    onClick={() => {
                      if (!rejection) {
                        setAlert("you must provide rejection reason");
                        setTimeout(() => {
                          setAlert(undefined);
                        }, 4000);
                        return;
                      }
                      packService
                        .acceptPackReview(props.row!, false, rejection)
                        .then(() => {
                          setInnerModal("hide");
                          props.setShowModal(false);
                        })
                        .catch((e) => {
                          setAlert("failed to reject, " + e.message);
                          setTimeout(() => {
                            setAlert(undefined);
                          }, 4000);
                        });
                    }}
                  >
                    Reject
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row style={{ margin: 25 }}>
                <h4>
                  Are you sure you want to{" "}
                  {innerModal === "accept" ? "make it" : "remove it from"}{" "}
                  public?
                </h4>
                <h5>{name}</h5>
              </Row>
              <Row className="justify-content-between">
                <Col sm="5">
                  <Button
                    className="w-100 h-100"
                    onClick={() => setInnerModal("hide")}
                  >
                    No
                  </Button>
                </Col>
                <Col sm="5">
                  <ButtonLoader
                    title="YES"
                    className="w-100 h-100"
                    onClick={() => {
                      return new Promise<void>((v, x) => {
                        packService
                          .acceptPackReview(
                            props.row!,
                            innerModal === "delete",
                            innerModal === "delete" ? "deleted" : undefined,
                          )
                          .then(() => {
                            setInnerModal("hide");
                            props.setShowModal(false);
                          })
                          .catch(() => {
                            setAlert(
                              `failed to ${innerModal === "accept" ? "publish" : "delete"}`,
                            );
                            setTimeout(() => {
                              setAlert(undefined);
                            }, 4000);
                          })
                          .finally(v);
                      });
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
          <Alert show={!!alert} variant="danger">
            <h5 style={{ margin: 2 }}>{alert}</h5>
          </Alert>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PackReviewModal;
