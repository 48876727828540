import { baseClient } from "./baseRequest";

export type AnalyticsRow = {
  clock: string;
  favorite: boolean;
  clock_time: number;
  display_time: number;
  auto_switch: boolean;
  collection_type: number;
};

export type KlydoApiClient = {
  search: (
    query: string,
    filters?: {
      excludeIds?: string[];
    },
  ) => Promise<{ ids: string[] }>;
  getKlydosAnalytics: (
    klydoId: string[],
    deviceId: string,
  ) => Promise<AnalyticsRow[]>;
};

export type KlydoSearchFilter = { excludeIds: string[] };

const klydoApiClient: KlydoApiClient = {
  search: async (query, { excludeIds } = {}) => {
    return baseClient.post<{ ids: string[] }>({
      url: `/klydos/.search?query=${query}`,
      body: {
        filters: { excludeIds },
      },
    });
  },

  getKlydosAnalytics: async (klydoId: string[], deviceId: string) => {
    return baseClient.post<AnalyticsRow[]>({
      url: `/klydos/get-klydos-analytics`,
      body: {
        klydoId,
        deviceId,
      },
    });
  },
};
export default klydoApiClient;
