import { useQuery } from "@tanstack/react-query";
import giftCategoriesClient from "../../../../api/giftCategories";

const useGiftOrdersQuery = () => {
  return useQuery({
    queryKey: ["giftOrders"],
    queryFn: () => giftCategoriesClient.listGiftOrders(),
  });
};
export default useGiftOrdersQuery;
