import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import { Gift } from "../../../../api/giftCategories";
import { NavLink } from "react-router-dom";
import useOrderQuery from "../hooks/useOrderInfoQuery";
import useOrderGiftMutation from "../hooks/useOrderGiftMutation";
import firebaseService from "../../../../firebase_service/firebaseService";
import { useState } from "react";

const GiftOrderModal = (props: {
  row: Gift;
  data: Gift[];
  setShowModal: (b: boolean) => void;
}) => {
  const row =
    props.data.find((d) => d.orderId === props.row.orderId) || props.row;
  const { data: orderInfo } = useOrderQuery(row.orderId);
  const { mutate: editOrderGift } = useOrderGiftMutation(row.orderId);
  const [clockFriendlies, setClockFriendlies] = useState<string>("");
  const updateOrderClocks = async () => {
    const idfs = clockFriendlies.split(",").map((c) => c.trim());
    const machines = await firebaseService.getDevicesByIdfs(idfs);
    const clocksIds = machines.map((m) => m.id);
    editOrderGift({ clocksIds });
  };
  return (
    <>
      <Modal.Header>
        <h4>{"Order Gift Details"}</h4>
        <i
          className="nc-icon nc-simple-remove"
          onClick={() => {
            props.setShowModal(false);
          }}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <Col>
              <p>
                <strong>Sender Name:</strong> {row.senderName}
              </p>
              <p>
                <strong>Full Name: </strong>
                {`${orderInfo?.first_name} ${orderInfo?.last_name}`}
              </p>
              <p>
                <strong>Email: </strong>
                {orderInfo?.email}
              </p>
              <p>
                <strong>Greeting:</strong> {row.greetingContent}
              </p>
              <p>
                <strong>Landing Page: </strong>
                {orderInfo?.firstVisit?.landingPage}
              </p>
              <p>
                <strong>Klydo ID:</strong>
                {
                  <NavLink
                    to="/admin/klydos"
                    onClick={() => {
                      localStorage.setItem("klydossearch", row.klydoId);
                    }}
                  >
                    {row.klydoId || "-No klydo available"}
                  </NavLink>
                }
              </p>
              {row.date && (
                <p>
                  <strong>Date:</strong>{" "}
                  {new Date(row.date).toLocaleDateString()}
                </p>
              )}
              {row.clocksIds && (
                <p>
                  <strong>Clock IDs:</strong> {row.clocksIds.join(", ")}
                </p>
              )}
              <Form>
                <Form.Group controlId="add clocks">
                  <Form.Label>Set Clocks</Form.Label>
                  <Form.Control
                    style={{ display: "inline-block" }}
                    type="text"
                    placeholder="Enter clocks friendly ids, seperated by ',' (no spaces)"
                    value={clockFriendlies}
                    onChange={(e) => setClockFriendlies(e.target.value)}
                  />
                  <Button
                    disabled={!clockFriendlies}
                    onClick={updateOrderClocks}
                  >
                    Set
                  </Button>
                </Form.Group>
              </Form>

              {row.senderImageUrl && (
                <div>
                  <p>
                    <strong>Sender Image:</strong>
                  </p>
                  <img
                    src={row.senderImageUrl}
                    alt="Sender"
                    style={{ maxWidth: "100px", borderRadius: "50%" }}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button
          variant="primary"
          onClick={() =>
            window.open(
              `https://editor.klydoclock.com/gift?orderId=${row.orderId}`,
              "_blank",
            )
          }
        >
          Watch Gift
        </Button>
      </Modal.Footer>
    </>
  );
};

export default GiftOrderModal;
