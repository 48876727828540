import { baseClient } from "./baseRequest";

export type VersionUpdate = {
  version: number;
  update: "public" | "beta";
};

export type AdminApiClient = {
  publishVersion: (versionUpdate: VersionUpdate) => Promise<void>;
};

const adminApiClient: AdminApiClient = {
  publishVersion: async (versionUpdate) => {
    return baseClient.post<void>({
      url: `/admin/publish-version`,
      body: versionUpdate,
    });
  },
};
export default adminApiClient;
