import { useMutation, useQueryClient } from "@tanstack/react-query";
import giftCategoriesClient from "../../../../api/giftCategories";

const useRemoveCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["categories"],
    mutationFn: (categoryId: string) =>
      giftCategoriesClient.remoteCategory(categoryId),
    onSuccess: () =>
      queryClient.refetchQueries({
        queryKey: ["categories"],
      }),
  });
};
export default useRemoveCategoryMutation;
