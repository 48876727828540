import { useState, useEffect } from "react";
import firebaseService from "../firebase_service/firebaseService";
// react-bootstrap components
import { Card, Container, Row, Col, Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { Kpi } from "../Types";

const DashboardKpi = ({
  headline,
  iconClass,
  details,
  foot,
  tableLinked,
}: {
  headline: string | JSX.Element;
  iconClass: string;
  details?: React.JSX.Element | number | string;
  foot?: string | JSX.Element;
  tableLinked?: { tableId: string; searchValue?: string };
}) => (
  <Card className="card-stats">
    <Card.Body>
      <Row>
        <Col xs="5">
          <div className="icon-big text-center icon-warning">
            <i className={iconClass}></i>
          </div>
        </Col>
        <Col xs="7">
          <div className="numbers">
            <p className="card-category">{headline}</p>
            <Card.Title as="h4">
              {tableLinked ? (
                <NavLink
                  to={"/admin/" + tableLinked.tableId}
                  onClick={() => {
                    if (tableLinked.searchValue) {
                      localStorage.setItem(
                        tableLinked.tableId + "search",
                        tableLinked.searchValue,
                      );
                    }
                  }}
                >
                  {details}
                </NavLink>
              ) : (
                details
              )}
            </Card.Title>
          </div>
        </Col>
      </Row>
    </Card.Body>
    <Card.Footer>
      <hr></hr>
      <div className="stats" style={{ width: "100%" }}>
        {foot}
      </div>
    </Card.Footer>
  </Card>
);
function Dashboard() {
  const frames: {
    [key: string]: number;
  } & { month: number; week: number; day: number } = {
    month: 60 * 60 * 24 * 30.5 * 1000,
    week: 60 * 60 * 24 * 7 * 1000,
    day: 60 * 60 * 24 * 1000,
  };
  const [totalTime, setTotalTime] = useState(0);
  const [timeFrame, setTimeFrame] = useState<
    "seconds" | "minutes" | "hours" | "days"
  >("seconds");
  const [kpis, setKpis] = useState<Kpi>();
  const [orderDates, setOrderDates] = useState<Date[]>();
  const [newRegisteres, setNewRegisteres] = useState<number>(0);
  const [newFav, setNewFav] = useState<number>(0);
  const [orderCount, setOrderCount] = useState<number[]>();
  const [timeWindow, setTimeWindow] = useState<{ key: string; value: number }>({
    key: localStorage.getItem("timeWindow") || "month",
    value: frames[localStorage.getItem("timeWindow") || "month"],
  });
  const [orderIn, setOrderIn] = useState<number>(0);
  const [ordersDifference, setOrdersDifference] = useState<number>(0);
  const countOrders = (dates: Date[]) => {
    const tday = new Date().getTime();
    const orderCountLocal = dates!.reduce(
      (monCount: number[], date: Date) => {
        const time = date.getTime();
        if (time > tday - timeWindow.value) monCount[0]++;
        else if (time > tday - timeWindow.value * 2) monCount[1]++;
        return monCount;
      },
      [0, 0],
    );
    setOrderCount(orderCountLocal);
    const curCount = orderCountLocal[0];
    const lastCount = orderCountLocal[1];
    setOrdersDifference(
      curCount === 0 && lastCount === 0
        ? 0
        : Math.round(
            (curCount * 100) / (lastCount > 0 ? lastCount : Number.MIN_VALUE) -
              100,
          ),
    );
    setOrderCount(orderCountLocal);
  };
  useEffect(() => {
    let prevListen: Kpi | undefined = undefined;

    const lKpis = (ks: Kpi) => {
      if (prevListen) {
        const newRegis = ks!.activeDevices! - prevListen.activeDevices!;
        setNewFav(ks!.favorites! - prevListen.favorites!);
        setNewRegisteres(newRegis);
      }
      prevListen = ks;
      setKpis(ks);
      const time = ks.time!;
      if (time < 99) {
        setTotalTime(time);
      } else if (time / 60 < 99) {
        setTimeFrame("minutes");
        setTotalTime(Math.floor(time / 60));
      } else if (time / 3600 < 99) {
        setTimeFrame("hours");
        setTotalTime(Math.floor(time / 3600));
      } else {
        setTimeFrame("days");
        setTotalTime(Math.floor(time / (24 * 3600)));
      }
    };

    const lOrders = (dates: Date[]) => {
      setOrderDates([...dates]);
      countOrders(dates);
      if (orderDates) {
        setOrderIn(dates.length - orderDates.length);
        setTimeout(() => {
          setOrderIn(0);
        }, 10000);
      }
    };

    firebaseService.listenOrders(lOrders);
    firebaseService.listenKpis(lKpis);

    return () => {
      firebaseService.stopListenKpis(lKpis);
      firebaseService.stopListenOrders(lOrders);
    };
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Active Devices"
              iconClass="nc-icon nc-chart text-warning"
              details={kpis?.activeDevices || <Spinner></Spinner>}
              tableLinked={{ tableId: "devices", searchValue: "" }}
              foot={
                <div>
                  {/* {kpis?.lastDayRegistered ? `${kpis?.lastDayRegistered} registered (last 24 hrs)` : <></>}<br /> */}
                  {newRegisteres > 0 && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FaArrowUp style={{ color: "green" }} /> &nbsp;+
                      {newRegisteres} last 15 mins
                    </div>
                  )}
                </div>
              }
            />
          </Col>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Live Devices"
              iconClass="nc-icon nc-chart text-success"
              details={
                (
                  <>
                    <p>{kpis?.liveDevices}</p>{" "}
                    <p style={{ fontSize: "12px" }}>
                      Last 24hr {kpis?.connectedDevices}
                    </p>
                  </>
                ) || <Spinner></Spinner>
              }
              foot={
                <>
                  <i className="far fa-calendar-alt mr-1"></i>
                  last 10 minutes
                </>
              }
              tableLinked={{
                tableId: "devices",
                searchValue: `heartbeat>${new Date().getTime() - 600000}//config`,
              }}
            />
          </Col>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline={
                <>
                  {orderIn > 0 && (
                    <p
                      className="card-category flash"
                      style={{ display: "inline", color: "#00ff00" }}
                    >
                      +{orderIn}{" "}
                    </p>
                  )}{" "}
                  <p style={{ display: "inline" }} className="card-category">
                    Shopify Orders
                  </p>
                </>
              }
              iconClass="nc-icon nc-cart-simple text-success"
              details={
                orderCount ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <p style={{ margin: 0, fontSize: "14px" }}>
                      {`This ${timeWindow.key}`}: {orderCount[0]}
                    </p>
                    <p style={{ margin: 0, fontSize: "14px" }}>
                      {`Last ${timeWindow.key}`}: {orderCount[1]}
                    </p>
                  </div>
                ) : (
                  <Spinner></Spinner>
                )
              }
              foot={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {ordersDifference !== 0 &&
                    Number.isFinite(ordersDifference) && (
                      <p
                        style={{
                          fontSize: "12px",
                          margin: 0,
                          color: `${ordersDifference > 0 ? "green" : "red"}`,
                        }}
                      >
                        {Math.abs(ordersDifference)}%
                        {ordersDifference > 0 ? (
                          <FaArrowUp
                            style={{
                              color: "green",
                              transform: "rotate(-35deg)",
                            }}
                          />
                        ) : (
                          <FaArrowDown
                            style={{
                              color: "red",
                              transform: "rotate(-35deg)",
                            }}
                          />
                        )}
                      </p>
                    )}
                  <p style={{ width: 0, margin: 0 }}></p>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <i
                      style={{
                        cursor: "pointer",
                        padding: 0,
                        border: 0,
                        position: "relative",
                      }}
                      className="nc-icon nc-settings-gear-64 btn"
                    >
                      <select
                        style={{
                          opacity: 0,
                          cursor: "pointer",
                          height: "17px",
                          position: "absolute",
                          width: "17px",
                          fontSize: "11px",
                          left: 0,
                          top: 0,
                        }}
                        className="styled-select"
                        defaultValue={timeWindow.key}
                        onChange={(v) => {
                          const value = v.target.value;
                          timeWindow.key = value;
                          timeWindow.value = frames[value];
                          setTimeWindow((prev) => ({ ...prev }));
                          countOrders(orderDates!);
                          localStorage.setItem("timeWindow", value);
                        }}
                      >
                        <option>month</option>
                        <option>week</option>
                        <option>day</option>
                      </select>
                    </i>
                  </div>
                </div>
              }
            />
          </Col>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Versions:"
              iconClass="nc-icon nc-light-3 text-success"
              details={
                firebaseService.general ? (
                  <p>
                    {`public: ${firebaseService.general.public}`}
                    <br />
                    {`beta: ${firebaseService.general.beta}`}
                    <br />
                    {`last: ${firebaseService.general.last}`}
                  </p>
                ) : (
                  <Spinner></Spinner>
                )
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Artists"
              iconClass="nc-icon nc-vector text-info"
              details={
                kpis ? (
                  <p>
                    {kpis?.artists} <br /> ({kpis?.artistsInPool} in pool)
                  </p>
                ) : (
                  <Spinner></Spinner>
                )
              }
              tableLinked={{
                tableId: "users",
                searchValue: "//activeCreators",
              }}
            />
          </Col>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Published Gifs"
              iconClass="nc-icon nc-palette text-warning"
              details={kpis?.publishedGifs || <Spinner></Spinner>}
            />
          </Col>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Pool"
              iconClass="nc-icon nc-palette text-info"
              details={kpis?.pool || <Spinner></Spinner>}
            />
          </Col>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Favorites"
              iconClass="nc-icon nc-favourite-28 text-danger"
              details={kpis?.favorites || <Spinner></Spinner>}
              foot={newFav > 0 ? `+ ${newFav} Last 15 mins` : ""}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Klydos in review"
              iconClass="nc-icon nc-palette text-info"
              details={kpis?.klydosInReview || <Spinner></Spinner>}
              tableLinked={{ tableId: "reviews" }}
            />
          </Col>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Total klydos viewing time"
              iconClass="nc-icon nc-watch-time text-info"
              details={totalTime || <Spinner></Spinner>}
              foot={timeFrame}
            />
          </Col>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Active Editors"
              iconClass="nc-icon nc-vector text-info"
              details={kpis?.activeEditors || <Spinner></Spinner>}
              tableLinked={{ tableId: "users", searchValue: "//activeEditors" }}
            />
          </Col>
          <Col lg="3" sm="6">
            <DashboardKpi
              headline="Version Issues"
              iconClass="nc-icon nc-paper-2 text-danger"
              details={kpis?.versionIssues}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
